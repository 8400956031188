import React from 'react';
import { Text, HStack, Skeleton, Icon,
  Table, Tbody, Tr, Td, Box, Button, VStack, Input, InputGroup, useToast, Heading
} from '@chakra-ui/react';
import { useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import { PlayerBadgeSkeleton } from './PlayerBadge';


function filterData(data_to_filter, filter_string){
  if(data_to_filter.length) {
    const result = data_to_filter.filter(singlePair => singlePair[0].toLowerCase().includes(filter_string.toLowerCase()));
    return result;
  }
}

function SingleRow(params){
  const toast = useToast();

  const add_player = async function(){
    let req = axios.post('https://api.noteefy.net/addplayer/', [params.username, params.cookies, params.chess_site]);
    if((await req).data != null){
      params.add_dummy_badge((await req).data);
    } else {
      toast({
        title: 'Could not add this player.',
        description: 'The account was deleted.',
        status: 'info',
        duration: 3000,
        isClosable: true
      });
    }
  }

  return(
    <Tr>
      <Td> {params.username}</Td>
      <Td><Button backgroundColor='#F2E7D5' onClick={add_player}> Add</Button></Td>
    </Tr>
  );
}

function Rows(params){
  let out = [];
  if(params.data) {
    for(const val of params.data){
      out.push(
        <SingleRow 
          username={val}
          add_dummy_badge={params.add_dummy_badge}
          cookies={params.sessionCookie} 
          selectedItems={params.selectedItems} 
          setSelectedItems={params.setSelectedItems}
          chess_site={params.chess_site}
        />
      );
    }
  }
  return out;
}

export function ButtonTextWithIcon({icon, text}) { 
  return (
    <HStack>
      <Icon as={icon}/> <Text>{text}</Text>
    </HStack>
  );
}

export function PlayersTable(params){
  const [reference_data, set_reference_data] = useState();
  const [filterString, setFilterString] = useState('');      
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const filterPlayersCallback = useCallback(async () => {
    if(filterString.length > 3){
      const new_data = filterData(reference_data, filterString);
      setData(new_data);
    }
  });

  function SkeletonRows(){
    let out = [];
    for(let i = 0; i < 10; i++){
      out.push(
        <Tr key={i}>
          <Td><Skeleton height="20px" width="100px" /></Td>
          <Td><Button backgroundColor='#F2E7D5'> Add</Button></Td>
        </Tr>
      );
    }
    return out;
  }

  const filterPlayersWithRequestCallback = useCallback(async () => {
    let req = await axios.get(params.url, {
      params: {
        filterstring: filterString,
        streamers: true,
        leaderboards: true,
        regular: true,
        site: params.chess_site
      }
    });
    set_reference_data(req.data);
    setData(req.data);
    setIsLoading(false);
    return req.data;
  });

  function AddPlayerFromString(){
    const toast = useToast();
    const add_player = async function(){  
      let req = axios.post('https://api.noteefy.net/addplayer/', [filterString, params.sessionCookie, params.chess_site]);
      if((await req).data != null){
        params.add_dummy_badge((await req).data);
      } else {
        toast({
          title: "Couldn't find this player.",
          description: "We couldn't find " + filterString + '.\nMaybe try different spelling.',
          status: 'info',
          duration: 3000,
          isClosable: true
        });
      }
    }

    if(filterString.length > 3){
      return(
        <Button backgroundColor='#F2E7D5' onClick={add_player}> Add</Button>
      );
    }
  }

  const filterStringChanged = useCallback(async (event) => {
    setFilterString(event.target.value);
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (params.filter_with_request){
        filterPlayersWithRequestCallback();
      } else {
        filterPlayersCallback();
      }
    }, 200);    
    return () => clearTimeout(timeOutId);
  }, [filterString]);

  return(
    <VStack width={'100%'}>
      <HStack width={'100%'}>
        <InputGroup width={'40%'}> 
          <Input 
            placeholder='filter players' 
            onChange={filterStringChanged} 
            backgroundColor={'brand.lightBackground'}
          />
        </InputGroup>
        <AddPlayerFromString username={filterString}/>
      </HStack>
      <Box width={'100%'} height={params.players_table_height} overflow={"scroll"}> 
        <Heading size='lg'>{params.title}</Heading>
        <Table variant="simple">
          <Tbody>
            {isLoading ? <SkeletonRows /> : <Rows 
              add_dummy_badge={params.add_dummy_badge} 
              data={data} 
              sessionCookie={params.sessionCookie} 
              selectedItems={params.selectedItems} 
              setSelectedItems={params.setSelectedItems}
              chess_site={params.chess_site}
            />}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  );
}

export function LoadingBadges(window_width, isMobile){
  return(
    [
      <PlayerBadgeSkeleton window_width={window_width} isMobile={isMobile}/>,
      <PlayerBadgeSkeleton window_width={window_width} isMobile={isMobile}/>,
      <PlayerBadgeSkeleton window_width={window_width} isMobile={isMobile}/>,
      <PlayerBadgeSkeleton window_width={window_width} isMobile={isMobile}/>,
      <PlayerBadgeSkeleton window_width={window_width} isMobile={isMobile}/>,
    ] );
}