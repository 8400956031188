import React, { useState, useEffect } from 'react'
import { Box, Icon, Flex, Spacer, Text, Center, VStack, HStack, Image} from '@chakra-ui/react';
import axios from 'axios';
import {FinishedGameBadge} from "./GameBadge"
import BestRatingIndicator from "./BestRatingIndicator"
import { useParams } from 'react-router-dom';
import { processDataString } from './EventsTable'


export default function PlayingSummary({isMobile, setShowHeader}) {
  const global_width = 550
  const badge_width = 480
  const hw_ratio = 1.3
  const global_height = hw_ratio * global_width
  const fontSize  = global_height/45
  const column_width = global_width/2.4
  const elements_spacing = 2
  const game_badge_width = column_width/1.1
  const small_elements_border_radius = global_width/70
  const [outEvents, setOutEvents] = useState([])
  const [data, setData] = useState({})
  const [event_to_show, set_event_to_show] = useState(null)

  const [data_ready, set_data_ready] = useState(false)

  const printRef = React.useRef();

  let {username} = useParams()
  let {site} = useParams()

  const handleGetEvents = async () => {
      const url = 'https://api.noteefy.net/events/';
      const timeout = 2000; // 2 seconds timeout
      const startTime = Date.now();
  
      try {
          const response = await fetch(url);
          const reader = response.body.getReader();
          let result = '';
          const decoder = new TextDecoder();
  
          while (Date.now() - startTime < timeout) {
              const { done, value } = await reader.read();
              if (done) break;
              result += decoder.decode(value, { stream: true });
          }
  
          result += decoder.decode(); // Decode any remaining bytes
          const splitted = result.split('\r\n');
          splitted.pop(); // Remove the last empty element
  
          const events = [];
          for (const element of splitted) {
              events.push(element);
          }
  
          console.log('Data fetched:', events);
          setOutEvents(events);
      } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error appropriately
          return [];
      }
  };
  
  useEffect(() => {
    handleGetEvents();
  }, []);

  useEffect(()=>{
    findCorrectEvent(outEvents, username, set_event_to_show);
  }, [outEvents])

  const handleGetONePlayerData = async() =>{
    let req = await axios.get('https://api.noteefy.net/playerdata/', {
      params: {
        username: username,
        site: site
      }
    })
    setShowHeader(false)
    setData(await req.data)
    set_data_ready(true)
  }
  useEffect(()=>{
    handleGetONePlayerData()
  }, [])

  let country = data.country 

  const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
      <path
        fill='currentColor'
        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
      />
    </Icon>
  )

  function OnlineIcon({is_online, is_playing}){
    if (is_playing == true){
      return(<CircleIcon color='red.600'/>)
    }
    if (is_online == true){
      return(<CircleIcon color='green.300'/>)
    }
    else return null
  }

  function timestamp_to_datetime(seen_val, is_online){
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (is_online == true){
      return 'online'
    }
    return new Date(seen_val).toLocaleDateString('en-GB', options) + ' ' + new Date(seen_val).toLocaleTimeString('en-GB')
  }

  function timestamp_to_date(seen_val){
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (seen_val == 'online'){
      return 'online'
    }
    return new Date(seen_val).toLocaleDateString('en-GB', options)
  }



  function HorizontalBadgeContainer(text, value){
    return (
    <Box width={column_width} p={elements_spacing} borderRadius={small_elements_border_radius} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
      <Flex>
      <Text fontSize={fontSize}>
        {text}:
      </Text>
      <Spacer/>
      <Text as='b' fontSize={fontSize}>
        {value}
      </Text>
      </Flex>
    </Box>)
  }

    function BadgeHeader(){
        return(
        <HStack p={elements_spacing}>
          <Center borderRadius={small_elements_border_radius} backgroundColor={'brand.beige'} width={global_width/14} height={global_width/14}>
          <Text fontSize={fontSize} as={'b'} color={'#393E46'}>{data.title}</Text>  
          </Center><Text fontSize={fontSize} as={'b'}> {data.username}</Text>
          <OnlineIcon is_online={data.online} is_playing={data.playing}/>
          <Spacer/>
          <BestRatingIndicator data={data} fontSize={fontSize} width={global_width/3.5} height={global_width/14}/>
          {/* <ShowHideButton changing_func={changeShow} width={global_width/10} height={global_width/14} image_box_size={global_width/14} /> */}
      </HStack>)
    }

  return (<>
  
              <Box //box with the badge
                  p={elements_spacing} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={small_elements_border_radius}
                  boxShadow="md"
                  width={badge_width}
                  ref={printRef}
                  // height={global_height}
                  backgroundColor={'brand.badgeBackground'}
              >
              <BadgeHeader isMobile = {isMobile}/>
                
                {data_ready ?
                <>
                <HStack>
                  <Box> {/*left side*/ }
                    <VStack >
                    <Box width={column_width} borderRadius={small_elements_border_radius} backgroundColor={'brand.badgePerformanceElement'} p={elements_spacing}>
                        <Center>
                        {site == 'Chesscom'  && data.avatar?
                          <Image src={data.avatar} boxSize={column_width/1.6} />:
                          <Image src='https://api.noteefy.net/static/Profile_picture.png' boxSize={column_width/1.6} />
                        }
                        </Center>
                    </Box>
                    
                    <Text fontSize={fontSize} as='b'>Profile information</Text>
                      {site != 'Chesscom' ?  
                      HorizontalBadgeContainer('Name', data.firstName + ' ' + data.lastName):
                      HorizontalBadgeContainer('Name', data.name)}

                      {site != 'Chesscom' ?
                      HorizontalBadgeContainer('Country', data.country):
                      HorizontalBadgeContainer('Country', country)
                      }
                      {HorizontalBadgeContainer('Joined', timestamp_to_date(data.createdAt))}
                      {/* <RemoveButton height={global_width/10}/> */}
                    </VStack>
                    
                    </Box>
                  <Box> {/*right side*/ }
                  <VStack>
                    <Box width={column_width} p={elements_spacing} borderRadius={small_elements_border_radius} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
                        <Text fontSize={fontSize} as='b'>Playing session summary:</Text>
                    </Box>
                    <VStack width={"100%"}>
                      {event_to_show != null ? EventToGames(event_to_show, fontSize) : null}
                    </VStack>
                        {/* {FinishedGameBadge('Bullet', 'https://api.noteefy.net/static/bullet.png', data, 'bullet', game_badge_width, fontSize )}
                        {FinishedGameBadge('Blitz', 'https://api.noteefy.net/static/blitz.png', data, 'blitz', game_badge_width, fontSize)}
                        {FinishedGameBadge('Rapid', 'https://api.noteefy.net/static/rapid.png', data, 'rapid', game_badge_width, fontSize)} */}
  ≥
                  </VStack>
                  </Box>
                </HStack>
                        {(data.firstName.length > 0 || data.lastName.length > 0) ?
                        null
                        : null}

                        </>
                          : null}


              </Box>
              </>
  )
}


function findCorrectEvent(events, expected_player, set_event_to_show){
  //find correct event - by the username and state (must be 2)
  // also games count must be > 0
  let found_event = null
  for (const data_string of events){
    const {
      site,
      player,
      state,
      game_names,
      game_counts,
      new_ratings,
      rating_changes,
      timestamp
    } = processDataString(data_string);
    if (state == '2' && player == expected_player && game_counts.length > 0){
      found_event = data_string
      break
    }
  
  }
  console.log('found event', found_event)
  set_event_to_show(found_event)
}

function EventToGames(event, fontSize){
  // parse event string to data with: game name, game count, new rating, rating change
  const {
    site,
    player,
    state,
    game_names,
    game_counts,
    new_ratings,
    rating_changes,
    timestamp
  } = processDataString(event);
  let out_badges = [];
  let i = 0;
  for (const temp of game_names.split('_')) {
    let game_name = game_names.split('_')[i].trim()
    let icon_url = 'https://api.noteefy.net/static/' + game_name + '.png';
    let games_count = game_counts.split('_')[i];
    let new_rating = new_ratings.split('_')[i];
    let rating_change = rating_changes.split('_')[i];
    out_badges.push(
      <FinishedGameBadge
        game_name={game_name}
        icon_url={icon_url}
        game_badge_width={"100%"}
        fontSize={fontSize}
        games_count={games_count}
        rating={new_rating}
        progress={rating_change}
      />

    );
    i += 1;
    // return mutliple FinishedGameBadges
  }
  return(
    out_badges
  )
}

