import React, { useEffect } from 'react';
import {Link as RouteLink} from "react-router-dom";
import {Heading, Text, Box, Button, Container,
  Link, Center, Image, Skeleton
} from '@chakra-ui/react'


function TopHeading(params) {
  return (
    <Box maxW="2xl" m="0 auto">
      <Heading as="h1" textAlign="center" fontSize="4xl" mt="50px">
        {params.text}
      </Heading>
    </Box>
  )
}


const NavLink = ({ text }) => (
  <Link>
    <Text fontSize="sm">{text}</Text>
  </Link>
);

function LinkButton({text, link}){
  return(<Button backgroundColor='#F2E7D5' height='8'>
        <RouteLink to={link}>
          <NavLink text={text} />
        </RouteLink>
        </Button>)
}

function ImageOrSkeleton({ src }) {
  return (
    <Center width={'100%'}>
      <Image src={src} 
       loading='lazy'
       fallback={<Skeleton height="200px" width="200px" />}
      />
    </Center>
  );
}


function QuickStart({isMobile}){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return(

  <Box p={5}
        width={'90%'}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
        
      <TopHeading text={"Noteefy Quickstart Guide"} />
      <Center>
      <Box width={isMobile? '100%' : '70%'}>

      
      <br/>
      <br/>
      <Heading size="md">1. Create your  account</Heading>
      <br/>
      First, you need to create your account on Noteefy using your email so that we can send you notifications.
      To register your account, go to <LinkButton text='Account' link='/account' /> page and fill in the form.
      <br/>
      After you have registered, you will be notified that your account has been created.
      <br/>
      <br/>
      <ImageOrSkeleton src={'https://api.noteefy.net/static/account_created.png'}/>
      <br/>
      If you want to just look around and check out the page, you can also use it without creating account. You can still add players from Lichess or Chess.com and the settings will be saved anonymously by your browser.
      This way you can view the notifications about the players in the table with statuses updates and check statistics of the player. If you want to also get notified by email when a player starts playing or goes online, just create an account.
      <br/>
      <br/>
      <br/>
      <Heading size="md">2. Confirm your account</Heading>
      <br/>
      Next, you will receive an email with a link to confirm your account. Click on the link to activate your account.
      After that, you can log in to the Noteefy site.
      <br/>
      <br/>
      <ImageOrSkeleton src={'https://api.noteefy.net/static/verification.png'}/>
      <br/>
      <br/>
      <br/>
      <Heading size="md">3. Add players</Heading>
      <br/>
      Now, sign in and go to <LinkButton text='Lichess' link='/lichess' /> or <LinkButton text='Chess.com' link='/chesscom' /> tab on Noteefy site to choose players you want to get notifications about.
      <br/>
      <br/>
      <ImageOrSkeleton src={'https://api.noteefy.net/static/add_player.gif'}/>
      <br/>
      <br/>
      <br/>
      <Heading size="md">4. Configure your notifications</Heading>
      <br/>
      Select players you want to get notifications using the serch box and set what kind of notifications you want to get.
      <br/>
      <b>Online</b> - you will receive a notification whenever the players shows online, <br/>
      <b>Playing</b> - you will receive a notification whenever the player is playing, <br/>
      <b>Finished playing</b> - you will receive a summary of players games once their playing session has finished.<br/>
      <br/>
      You will receive the notification immadiately after the player goes online or starts playing. However, if the player goes offline and then returns online within 10 minutes, you won't receive another notification.
      The same applies to the playing notifications. If the player finishes playing and then starts playing again within 10 minutes, you won't receive another notification about the player starting to play. If you only set the finished playing notification, you will receive the summary of the games after 10 minutes of the player's inactivity.
      <br/>
      <ImageOrSkeleton src={'https://api.noteefy.net/static/set_notifications.gif'}/>
      <br/>
      <br/>
      <br/>
      <Heading size="md">5. That's it!</Heading>
      <br/>
      Now, just wait for the notifications to come. You can also check the status of the players in the table on the main page.
      <br/>
      <br/>
      <Container width={isMobile ? '70%' : '50%'}>
        <Image src={'https://api.noteefy.net/static/Magnus_playing.png'}/>
      </Container>
      </Box>
      </Center>
    </Box>

  )}

export default QuickStart
