import React from 'react'
import { Flex, Skeleton, Spacer } from '@chakra-ui/react'
import { Box, Image, Text, Center, VStack, HStack} from '@chakra-ui/react';


function Rating({value, progress, fontSize, width=null}){
  let progress_color = 'grey'
  let prefix = '+'
  if (progress>0){
    progress_color = 'green'
  } else if (progress < 0){
    progress_color = 'red'
    prefix = ''
  }
  return(
    <VStack spacing={0} width={width}>
    <Text as='b' fontSize={fontSize}>{value}</Text>
    <Text as='b' fontSize={fontSize} color={progress_color}>{prefix + String(progress)}</Text>
    </VStack>
  )
}

export default function GameBadge(game_name, icon_url, game_data, game_data_name, game_badge_width, fontSize) {
  let progress = 0
  let rating = 0
  if(game_data_name in game_data){
    progress = game_data[game_data_name]['prog']
    rating = game_data[game_data_name]['rating']
  }

  return (
              <Box //box with the badge
                  p={2} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={10}
                  width={game_badge_width}
                  boxShadow="md"
                  backgroundColor={'brand.badgePerformanceElement'}
              >
              <VStack>
                <Text fontSize={fontSize} >{game_name}</Text>
                <Rating value={rating} progress={progress} fontSize={fontSize}/>
                <Image src={icon_url}/>
              </VStack>
              </Box>

  )
}

export function FinishedGameBadge({game_name, icon_url, game_badge_width, fontSize, games_count, progress, rating}) {

  let text_to_show = String(games_count) + ' ' + game_name
  if (Number(games_count) > 1){
    text_to_show += ' games'
  } else {
    text_to_show += ' game'
  }
  return (
              <Box //box with the badge
                  p={2} // distance from the border - NOT MARGIN - margin is outside the box
                  borderRadius={10}
                  width={game_badge_width}
                  boxShadow="md"
                  backgroundColor={'brand.badgePerformanceElement'}
              >
              <Flex>
                <Text fontSize={fontSize} width={"33%"} >{text_to_show}</Text>
                <Spacer/>
                <Rating value={rating} progress={Number(progress)} fontSize={fontSize} width={"33%"}/>
                <Spacer/>
                <Image src={icon_url} boxSize={fontSize*3}/>
              </Flex>
              </Box>

  )
}

export function GameBadgeSkeleton({game_badge_width, game_badge_height, fontSize}) {
  return (
        <Box //box with the badge
            p={2} // distance from the border - NOT MARGIN - margin is outside the box
            borderRadius={10}
            width={game_badge_width}
            height={game_badge_height}
            boxShadow="md"
            backgroundColor={'brand.badgePerformanceElement'}
        >
        <Center height={"100%"} width={"100%"}>
        <Skeleton height={fontSize} width={game_badge_width/2} startColor='grey' endColor='brand.badgePerformanceElement' borderRadius={3}/>
        </Center>
        </Box>
  )
}
