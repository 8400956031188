import React from 'react'
import {Heading, Text, Box, Button,
  Link,
HStack,
Center
} from '@chakra-ui/react'

function TopHeading(params) {
  return (
    <Box maxW="2xl" m="0 auto">
      <Heading as="h1" textAlign="center" fontSize="4xl" mt="50px">
        {params.text}
      </Heading>
    </Box>
  )
}



function OuterServiceLink(text, link){
  return (
    <Box borderRadius = {7} p={4} boxShadow="md" backgroundColor={'brand.lightSmallElement'}>
      <Center>
      <Link href={link} isExternal>
        <Button onClick={(e) => e.stopPropagation()} boxShadow="md" backgroundColor='brand.badgePerformanceElement'>{text}</Button>
      </Link>
      </Center>
    </Box>)
}


function AboutPage({isMobile}){
  
  return(
  <Center height={'80%'}>


  <Box p={5}
        width={'90%'}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
        
      <TopHeading text={"About Noteefy"} />
      <Center>
      <Box width={isMobile? '100%' : '50%'}>

      
      <br/>
      <br/>
      Noteefy is a 100% free web app that will send you email notifications about Lichess and Chess.com players.
      <br/>
      <br/>
      We would like to highlight that Noteefy offers insights into players' online activity and statistics. 
      We encourage you to use this information responsibly and, more importantly, to refrain from any form of stalking. 
      Let's foster a positive and respectful chess community.
      <br/>
      <br/>
      If you want to contribute, tell your friends about the page and visit our Twitter/X.
      <br/>
      Also, if you have any ideas that will improve the Noteefy site, we are interested in hearing from you!
      <br/>
      <br/>
      Mail us at <b> info@noteefy.net</b>
      <br/>
      <br/>
      </Box>
      </Center>
      <Center width={'100%'}>
      <HStack>
        {OuterServiceLink('Twitter', 'https://twitter.com/noteefychess')}  
        {OuterServiceLink('Buy us a coffee', 'https://www.buymeacoffee.com/frodulec')}  
      </HStack>
      </Center>

      <br/>
      <b>Information about cookies: </b>
      <br/>
      Noteefy only uses cookies for technical purposes, which does not require the website user consent.
      <Text align="right" fontSize={10} color={"gray"}>Version 1.0.14</Text>
    </Box>
    
    </Center>

  )}

export default AboutPage
