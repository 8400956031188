import React from 'react'
import { useEffect, useState, useRef} from "react";
import {Box, Button, Text, HStack, Tab, TabList, TabPanels, TabPanel, Tabs, VStack, Center, Spacer, SimpleGrid } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import { MdPeople } from 'react-icons/md'
import axios from 'axios';
import PlayerBadge from "./PlayerBadge"
import { useCookies } from 'react-cookie'
import { ButtonTextWithIcon, PlayersTable, LoadingBadges} from './commons';



export default function LichessTab(params) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [cookies] = useCookies('sessionCookie');
  const [badgesItems, setBadgesItems] = useState({});
  const [outBadges, setOutBadges] = useState(
    LoadingBadges(params.window_width, params.isMobile)
  );
  const [testNumber, setTestNumber] = useState(4)
  const [tabIndex, setTabIndex] = useState(0)
  const [loadedPlayers, setLoadedPlayers] = useState(false)
  const p_val = 5
  const title_box_height = 20
  const header_height = 20
  const inner_grid_height = params.height - title_box_height - header_height - p_val * 4 //?
  
  useEffect(()=>{
    if(params.userLogin.length > 0)
    {
      handleUpdateSelectedList()
    }
    
  }, [params.isLoggedIn, params.userLogin])

useEffect(()=>{
    const localBadges = []
    Object.keys(badgesItems).forEach(function(key, index) {
      let username = 'NoUsername'
      const seen_at = badgesItems[key]['seenAt']
      const online = badgesItems[key]['online']
      const playing = badgesItems[key]['playing']
      if('username' in badgesItems[key]){
        username = badgesItems[key]['username']
      }
      localBadges.push({
        seen_at,
        online,
        playing,
        badge: <PlayerBadge
          window_width = {params.window_width}
          tv_link = {'http://lichess.org/@/' + username.toLowerCase() + '/tv'}
          profile_link = {'http://lichess.org/@/' + username.toLowerCase()}
          cookies={cookies['sessionId']}
          chess_site='Lichess'
          isMobile ={params.isMobile}
          showBadge ={false}
          handleUpdateBadges = {handleUpdateBadges}
          badges={badgesItems}

          nick={username} 
          data={badgesItems[key]}
        />
      })
    })

    localBadges.sort((a, b) => {
      if (a.playing && !b.playing) return -1;
      if (!a.playing && b.playing) return 1;
      if (a.online && !b.online) return -1;
      if (!a.online && b.online) return 1;
      return new Date(b.seen_at) - new Date(a.seen_at);
    })

    if (params.userLogin.length > 0){
      if (loadedPlayers){
        handleSetOutBadges(localBadges.map(item => item.badge))
      }}
    else{
      handleSetOutBadges(localBadges.map(item => item.badge))
    }
}, [JSON.stringify(badgesItems)])


  const handleUpdateSelectedList = async() =>{
    let req = await axios.get('https://api.noteefy.net/loadsettings/', {
      params: {
        userLogin: params.userLogin,
        chess_site: 'Lichess'
      }
    })
    handleUpdateBadges(await req.data)
  }
  
  
  const handleUpdateBadges = (users) =>{
   setBadgesItems(users)
   setLoadedPlayers(true)
   setTestNumber(testNumber + 1)
  }

  const handleSetOutBadges = (users) =>{
   setOutBadges(users)
   if(loadedPlayers){
    sendPlayers()
   }
  }

  const sendPlayers = () => {
    let req = new XMLHttpRequest()
    req.open('POST', 'https://api.noteefy.net/savesettings/')
    const outlist = []
    for(let item in badgesItems){
      let player_data = [item, badgesItems[item].settings] 
      outlist.push(player_data)
    }

    req.send(JSON.stringify([params.userLogin, outlist, 'Lichess']))
  }
  const add_dummy_badge = function(data){
      let abc = badgesItems
      abc[data['username']] = data
    setBadgesItems(abc)
    setTestNumber(testNumber + 1)
  }

  const NoBadges = function(){
    const global_width = params.isMobile? params.window_width/1.1 : 550
  const badge_width = params.isMobile? global_width / 1.1 : global_width
  const elements_spacing = 2
  const small_elements_border_radius = global_width/70
    return(
      <Box //box with the badge
      p={elements_spacing} // distance from the border - NOT MARGIN - margin is outside the box
      borderRadius={small_elements_border_radius}
      boxShadow="md"
      width={badge_width}
      backgroundColor={'brand.badgeBackground'}
    >
      <HStack>
      <Text>
        Your players list is currently empty. Select players from the  
        {params.isMobile?<Button backgroundColor='#F2E7D5' onClick={e => handleTabsChange(1)}>
        
        <ButtonTextWithIcon icon={SearchIcon} text='Add players'/>
        </Button > : ' search box'
        }
      </Text>
      </HStack>
  
  </Box>
    )
  }


  const SelectedPlayers = function({height}){
    // add ref to the box with the list of players
    const [players_list_description_height, set_players_list_description_height] = useState(0)

    let width = '90%'
    if(params.isMobile){
      width = '100%'
    } 
    return(
      <VStack width={'100%'}height={height- 50 - players_list_description_height}>
        <LichessPlayersTextBox set_height={set_players_list_description_height}/>
      
    <Box height={height} width={width} overflowY="auto">
      
      <VStack columns={1} spacing='20px'>
        <Spacer/>
        {outBadges.length > 0 ? outBadges : <NoBadges></NoBadges>}
        <Spacer/>
      </VStack>
    </Box>
    </VStack>
    )}



const SearchList = function(){
  // box with the list + filter box + description
  const description_box_ref = useRef(null)
  const [playersTableHeight, setPlayersTableHeight] = useState(0);
  useEffect(() => {
    if (description_box_ref.current) {
      setPlayersTableHeight(inner_grid_height - description_box_ref.current.clientHeight - 100);
    }
  }, [description_box_ref.current, inner_grid_height]);
  let width = '90%'
    if(params.isMobile){
      width = '100%'
    }
    return(
      <VStack  width={width}>
      <Box p={3} 
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md"
        ref={description_box_ref}>
        
        <Text>
          Select players from the table below. To select a specified player, start typing in the filter box below. 
          If no player with the username is shown in the list, but you are sure that such Lichess account exists, 
          you can click 'Add' near the filter box to try adding player with this exact username.
        </Text>
      </Box>
    <PlayersTable 
      is_mobile={params.isMobile}
      add_dummy_badge={add_dummy_badge} 
      title=''
      url='https://api.noteefy.net/players' 
      filter_with_request={true} 
      sessionCookie={cookies['sessionId']} 
      selectedItems={selectedItems} 
      setSelectedItems={setSelectedItems}
      players_table_height={playersTableHeight}
      chess_site='Lichess'
    />
    </VStack>
    )
}

const LichessPlayersTextBox = function({width, set_height}){
  const description_box_ref = useRef(null)
  useEffect(() => {
    if (description_box_ref.current) {
      set_height(description_box_ref.current.offsetHeight);
    }
  }, []);
  return(
  <Box p={3}
  ref={description_box_ref} 
  width={width}
        backgroundColor={'brand.badgeBackground'}
        borderRadius={10}
        boxShadow="md">
        <Text>
          To get notifications regarding a Lichess player, add players from the search box. 
          To choose what kind of notifications are you intersted in, go to a players detailed settings and tick or untick accordingly.
        </Text>
 </Box>
 )
}

const handleTabsChange = (index) => {
  setTabIndex(index)
}
  
  return (

<>
{params.isMobile?
<Tabs variant='soft-rounded'
  colorScheme='gray' 
  width={'100%'} 
  index={tabIndex} 
  onChange={handleTabsChange}>
<Center>
<TabList width={'100%'} >
  <Tab borderRadius={8} width={'40%'} backgroundColor={'brand.badgePerformanceElement'} ><ButtonTextWithIcon icon={MdPeople} text={'Your list'}/></Tab>
  <Spacer/>
  <Tab borderRadius={8} width={'40%'}backgroundColor={'brand.badgePerformanceElement'}><ButtonTextWithIcon icon={SearchIcon} text='Add players'/></Tab>
</TabList>
</Center>
  <TabPanels>
    <TabPanel>
      <SelectedPlayers/>
    </TabPanel>
    <TabPanel>
      <SearchList/>
    </TabPanel>
  </TabPanels>
</Tabs> 
: 
<VStack height={params.height - header_height}> { /* whole page */}
<Box width={'90%'} height={title_box_height}> {/* box ith only the title of the page */}
  <Center>
<Text as={'b'} fontSize={'xl'}>
  Lichess notifications
</Text>

</Center>

</Box>

<SimpleGrid
  columns={2}
  p={5}
  // height={params.height - title_box_height - header_height - 20}
>
  {/* height without the title */}
  <SearchList isMobile={params.isMobile} height={params.height}/>
  <SelectedPlayers isMobile={params.isMobile} height={params.height}/>
</SimpleGrid>
</VStack>
}           
  </>      
  );
}